<template>
  <div class="fadeInLeft form-row">
    <div>
      <h1 class="h4 font-weight-bold">
        Setup your mail driver.
      </h1>
      <p class="text-muted mb-2">
        We can send the emails for you but we know many prefer to use their own
        mail driver for sending emails.
      </p>
      <p class="text-muted">
        Configure an email driver for sending emails. You can also configure
        third party providers like Mailgun, SES etc.
      </p>
    </div>
    <div class="form col-12 mb-3">
      <div v-if="mail_config_data">
        <component
          :is="mail_driver"
          ref="mailDriver"
          :config-data="mail_config_data"
          :loading="loading"
          :mail-drivers="mail_drivers"
          @on-change-driver="
            (val) => (mail_driver = mail_config_data.mail_driver = val)
          "
          @data-changed="setEmailConfig"
        />
      </div>
    </div>
    <div class="form-group col-md-12">
      <div class="border col-12" />
    </div>
    <div v-if="showTest" class="form-group col-md-6">
      <button
        :loading="loading"
        class="btn btn-secondary col-12"
        @click="openMailTestModal"
      >
        Test email
      </button>
    </div>
    <div class="form-group col-md-6 ml-auto">
      <button
        :loading="loading"
        class="btn btn-secondary col-12"
        @click="saveEmailConfig"
      >
        Save configuration
      </button>
    </div>
    <div class="form-group col-md-12">
      <component :is="component" @hideComponent="component = ''" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Smtp from "@/components/MailDriver/Smtp.vue";
import Mailgun from "@/components/MailDriver/Mailgun.vue";
import Ses from "@/components/MailDriver/Ses.vue";
import Mail from "@/components/MailDriver/Mail.vue";
import None from "@/components/MailDriver/None.vue";
import MailTestModal from "@/components/UI/MailTestModal.vue";
export default {
  name: "EmailConfigurator",
  components: {
    Smtp,
    Mailgun,
    Ses,
    Mail,
    None,
    MailTestModal,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      id: null,
      mail_config_data: {},
      mail_driver: "mail",
      loading: false,
      component: null,
      mail_drivers: [],
      step_status: {
        key: "configure_driver",
        status: true,
      },
      showTest: false,
    };
  },
  mounted() {
    jsonAPI.get("/integration/email").then((response) => {
      const data = response.data.data;
      this.mail_drivers = data.drivers;
      this.mail_config_data = Object.assign(
        this.mail_config_data,
        data.settings
      );

      // get mail driver
      if (response.data.settings.mail_driver) {
        this.mail_driver = response.data.settings.mail_driver;
        this.showTest = true;
        this.$emit("proceed");
      }

      // check if from_mail is empty and use current user email and name
      if (!this.mail_config_data.from_mail) {
        this.mail_config_data.from_mail = this.getUser.email;
        this.mail_config_data.from_name = this.getUser.name;
      }
      this.$refs.mailDriver.setMailDriver(this.mail_config_data);
    });
  },
  methods: {
    async saveEmailConfig(mailConfigData) {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          let configData = Object.assign(this.mail_config_data, mailConfigData);
          if (this.mail_driver === "none") {
            configData.mail_driver = "none";
            configData.mail_host = "";
            configData.mail_mailgun_domain = "";
            configData.mail_mailgun_endpoint = "";
            configData.mail_mailgun_secret = "";
            configData.mail_password = "";
            configData.mail_port = "";
            configData.mail_ses_key = "";
            configData.mail_ses_secret = "";
            configData.mail_username = "";
          }
          const data = {
            provider: this.mail_driver,
            type: 'email',
            data: configData
          }
          jsonAPI
            .post("/integration", data)
            .then(() => {
              this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);
              this.showTest = true;
              window.Bus.$emit("flash-message", {
                text: "Email settings updated",
                type: "success",
              });
              this.loading = false;
              this.$emit("proceed");
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    async setEmailConfig(mailConfigData) {
      this.mail_config_data = Object.assign(
        this.mail_config_data,
        mailConfigData
      );
    },
    openMailTestModal() {
      this.component = "MailTestModal";
      this.$store.dispatch("openModal", {
        title: "Test Mail Configuration",
        sender_email: this.mail_config_data.from_mail,
        sender_name: this.mail_config_data.from_name,
      });
    },
  },
};
</script>

<style scoped></style>
