<template>
  <component :is="layout">
    <div class="row fadeInUp mb-6" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <ConfigurationNav />
          </div>
          <div class="col-12 col-md-9">
            <div class="card border-0 shadow-sm">
              <HelpModal class="position-fixed p-4 justify-content-between" v-if="helpModal" @close="openHelp">
                <p>
                  Choose the mail driver you prefer to use, if you choose Mail we will use our own mail sender to deliver your emails.
                </p>
              </HelpModal>
              <div class="card-header">
                <h4>
                  Driver configuration

                </h4>
              </div>
              <div class="card-body">
                <email-configurator />
              </div>
              <div class="page-help">
                <a @click="openHelp">
                  <QuestionCircle />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import "intro.js/minified/introjs.min.css";
import introJs from "intro.js/intro.js";
import { mapGetters } from "vuex";
import Default from "@/layouts/default.vue";
import EmailConfigurator from "@/components/Onboarding/EmailConfigurator.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import QuestionCircle from "@/components/Svg/QuestionCircle.vue";
import HelpModal from "@/components/UI/HelpModal.vue";

export default {
  components: {
    Default,
    EmailConfigurator,
    ConfigurationNav,
    QuestionCircle,
    HelpModal,
  },
  data() {
    return {
      layout: "Default",
      helpModal: false,
    };
  },
  computed: {
    ...mapGetters(["getOrganization"]),
    getName() {
      return this.getOrganization.title ?? "";
    },
  },
  methods: {
    runPageIntro() {
      introJs().onchange(function(targetElement) {
        console.log(targetElement);
      });
      introJs().start();
    },
    openHelp() {
      this.helpModal = !this.helpModal;
      window.scrollTo(0,0);
    },
  },
};
</script>

<style scoped></style>
