<template>
  <div class="form-row">
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('mail_driver') }"
    >
      <label for="mail_driver" class="control-label"
        >Select an email driver:</label
      >
      <Multiselect
        v-model="mailConfigData.mail_driver"
        :invalid="errors.has('mail_driver')"
        :options="mailDrivers"
        :searchable="true"
        :allow-empty="false"
        :show-labels="false"
        @input="onChangeDriver"
      />
      <span v-show="errors.has('mail_driver')" class="invalid-feedback">{{
        errors.first("mail_driver")
      }}</span>
    </div>
    <div
      class="form-group col-md-6 required"
      :class="{ 'is-invalid': errors.has('from_mail') }"
    >
      <label for="from_mail" class="control-label">From email:</label>
      <input
        id="from_mail"
        v-model="mailConfigData.from_mail"
        v-validate="{ required: true }"
        type="email"
        name="from_mail"
        class="form-control"
      />
      <span v-show="errors.has('from_mail')" class="invalid-feedback">{{
        errors.first("from_mail")
      }}</span>
    </div>
    <div
      class="form-group col-md-6 required"
      :class="{ 'is-invalid': errors.has('from_name') }"
    >
      <label for="from_name" class="control-label">From name:</label>
      <input
        id="from_name"
        v-model="mailConfigData.from_name"
        v-validate="{ required: true }"
        type="text"
        name="from_name"
        class="form-control"
      />
      <span v-show="errors.has('from_name')" class="invalid-feedback">{{
        errors.first("from_name")
      }}</span>
    </div>
    <div
      class="form-group col-md-6 required"
      :class="{ 'is-invalid': errors.has('mail_host') }"
    >
      <label for="mail_host" class="control-label">Mail host:</label>
      <input
        id="mail_host"
        v-model="mailConfigData.mail_host"
        v-validate="{ required: true }"
        type="text"
        name="mail_host"
        class="form-control"
      />
      <span v-show="errors.has('mail_host')" class="invalid-feedback">{{
        errors.first("mail_host")
      }}</span>
    </div>
    <div
      class="form-group col-md-6 required"
      :class="{ 'is-invalid': errors.has('mail_port') }"
    >
      <label for="mail_port" class="control-label">Mail port:</label>
      <input
        id="mail_port"
        v-model="mailConfigData.mail_port"
        v-validate="{ required: true }"
        type="text"
        name="mail_port"
        class="form-control"
      />
      <span v-show="errors.has('mail_port')" class="invalid-feedback">{{
        errors.first("mail_port")
      }}</span>
    </div>
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('mail_encryption') }"
    >
      <label for="mail_encryption" class="control-label"
        >Encryption type:</label
      >
      <Multiselect
        v-model.trim="mailConfigData.mail_encryption"
        :invalid="errors.has('mail_encryption')"
        :options="encryptions"
        :searchable="true"
        :show-labels="false"
      />
      <span v-show="errors.has('mail_encryption')" class="invalid-feedback">{{
        errors.first("mail_encryption")
      }}</span>
    </div>
    <div
      class="form-group col-md-6 required"
      :class="{ 'is-invalid': errors.has('mail_mailgun_domain') }"
    >
      <label for="mail_mailgun_domain" class="control-label"
        >Mailgun domain:</label
      >
      <input
        id="mail_mailgun_domain"
        v-model="mailConfigData.mail_mailgun_domain"
        v-validate="{ required: true }"
        type="text"
        name="mail_mailgun_domain"
        class="form-control"
      />
      <span
        v-show="errors.has('mail_mailgun_domain')"
        class="invalid-feedback"
        >{{ errors.first("mail_mailgun_domain") }}</span
      >
    </div>
    <div
      class="form-group col-md-6 required"
      :class="{ 'is-invalid': errors.has('mail_mailgun_secret') }"
    >
      <label for="mail_mailgun_secret" class="control-label"
        >Mailgun secret:</label
      >
      <input
        id="mail_mailgun_secret"
        v-model="mailConfigData.mail_mailgun_secret"
        v-validate="{ required: true }"
        type="text"
        name="mail_mailgun_secret"
        class="form-control"
      />
      <span
        v-show="errors.has('mail_mailgun_secret')"
        class="invalid-feedback"
        >{{ errors.first("mail_mailgun_secret") }}</span
      >
    </div>
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('mail_mailgun_endpoint') }"
    >
      <label for="mail_mailgun_endpoint" class="control-label"
        >Mailgun endpoint:</label
      >
      <input
        id="mail_mailgun_endpoint"
        v-model="mailConfigData.mail_mailgun_endpoint"
        v-validate="{ required: true }"
        type="text"
        name="mail_mailgun_endpoint"
        class="form-control"
      />
      <span
        v-show="errors.has('mail_mailgun_endpoint')"
        class="invalid-feedback"
        >{{ errors.first("mail_mailgun_endpoint") }}</span
      >
    </div>
    <div class="form-group col-6 mb-0">
      <slot />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Mailgun",
  components: {
    Multiselect,
  },
  inject: ["$validator"],
  props: {
    configData: {
      type: Object,
      require: true,
      default: Object,
    },
    loading: {
      type: Boolean,
      require: true,
      default: false,
    },
    mailDrivers: {
      type: Array,
      require: true,
      default: Array,
    },
  },
  data() {
    return {
      mailConfigData: {
        mail_driver: null,
        mail_host: null,
        mail_port: null,
        mail_mailgun_domain: null,
        mail_mailgun_secret: null,
        mail_mailgun_endpoint: null,
        mail_encryption: "tls",
        from_mail: null,
        from_name: null,
      },
      encryptions: ["tls", "ssl", "starttls"],
    };
  },
  watch: {
    mailConfigData: {
      deep: true,
      handler() {
        this.$emit("data-changed", this.mailConfigData);
      },
    },
  },
  methods: {
    onChangeDriver() {
      this.$emit("on-change-driver", this.mailConfigData.mail_driver);
    },
    setMailDriver(configData) {
      for (const key in this.mailConfigData) {
        if (Object.prototype.hasOwnProperty.call(configData, key)) {
          this.mailConfigData[key] = configData[key];
        }
      }
    },
  },
};
</script>
