<template>
  <div>
    <div class="form-row">
      <div
        class="form-group col-md-12 required"
        :class="{ 'is-invalid': errors.has('mail_driver') }"
        data-title="Choose the mail driver you prefer to use, if you choose Mail we will use our own mail sender to deliver your emails."
        data-intro=""
        data-step="1"
      >
        <label for="mail_driver" class="control-label"
          >Select an email driver:</label
        >
        <Multiselect
          v-model="mailConfigData.mail_driver"
          :invalid="errors.has('mail_driver')"
          :options="mailDrivers"
          :searchable="true"
          :allow-empty="false"
          :show-labels="false"
          @input="onChangeDriver"
        />
        <span v-show="errors.has('mail_driver')" class="invalid-feedback">{{
          errors.first("mail_driver")
        }}</span>
      </div>
      <div
        class="form-group col-md-6 required"
        :class="{ 'is-invalid': errors.has('from_mail') }"
        data-title="Enter the sending email, this will be set at the 'from' mail."
        data-intro=""
        data-step="2"
      >
        <label for="from_mail" class="control-label">From email:</label>
        <input
          id="from_mail"
          v-model="mailConfigData.from_mail"
          v-validate="{ required: true }"
          type="email"
          name="from_mail"
          class="form-control"
        />
        <span v-show="errors.has('from_mail')" class="invalid-feedback">{{
          errors.first("from_mail")
        }}</span>
      </div>
      <div
        class="form-group col-md-6 required"
        :class="{ 'is-invalid': errors.has('from_name') }"
        data-title="Enter a sender name, this will be set at the 'from' name."
        data-intro=""
        data-step="3"
      >
        <label for="from_name" class="control-label">From name:</label>
        <input
          id="from_name"
          v-model="mailConfigData.from_name"
          v-validate="{ required: true }"
          type="text"
          name="from_name"
          class="form-control"
        />
        <span v-show="errors.has('from_name')" class="invalid-feedback">{{
          errors.first("from_name")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Mail",
  components: {
    Multiselect,
  },
  inject: ["$validator"],
  props: {
    configData: {
      type: Object,
      require: true,
      default: Object,
    },
    loading: {
      type: Boolean,
      require: true,
      default: false,
    },
    mailDrivers: {
      type: Array,
      require: true,
      default: Array,
    },
  },
  data() {
    return {
      mailConfigData: {
        mail_driver: "",
        from_mail: null,
        from_name: null,
      },
    };
  },
  watch: {
    mailConfigData: {
      deep: true,
      handler() {
        this.$emit("data-changed", this.mailConfigData);
      },
    },
  },
  methods: {
    onChangeDriver() {
      this.$emit("on-change-driver", this.mailConfigData.mail_driver);
    },
    setMailDriver(configData) {
      for (const key in this.mailConfigData) {
        if (Object.prototype.hasOwnProperty.call(configData, key)) {
          this.mailConfigData[key] = configData[key];
        }
      }
    },
  },
};
</script>
